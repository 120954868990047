<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../store'

export default {
  name: 'DocumentStatusMixin',
  created () {
    if (!this.documentStatusChoicesList || (this.documentStatusChoicesList && this.documentStatusChoicesList.length === 0)) {
      this.getDocumentStatusList()
    }
  },
  computed: {
    ...mapGetters({
      documentStatusChoicesList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices
    }),
    documentStatusOptions () {
      const options = []
      if (this.documentStatusChoicesList && this.documentStatusChoicesList.length > 0) {
        this.documentStatusChoicesList.forEach((documentStatus) => {
          options.push({
            value: documentStatus[0],
            text: documentStatus[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getDocumentStatusList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices
    }),
    selectDocumentStatus (item) {
      this.$set(this.form, 'status', item.value)
      this.$set(this.form, 'status_name', item.text)
    },
    clearDocumentStatus () {
      this.$set(this.form, 'status', null)
      this.$set(this.form, 'status_name', null)
    }
  }
}
</script>
