<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from '../upload-document/store'

export default {
  name: 'DocStatusMixin',
  created () {
    if (!this.uploadDocumentStatusList || (this.uploadDocumentStatusList && this.uploadDocumentStatusList.length === 0)) {
      this.getDocumentStatusChoices()
    }
  },
  computed: {
    ...mapGetters({
      uploadDocumentStatusList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices
    }),
    uploadDocumentStatusListOptions () {
      const options = []
      if (this.uploadDocumentStatusList && this.uploadDocumentStatusList.length > 0) {
        this.uploadDocumentStatusList.forEach((docStatus) => {
          const option = {
            value: docStatus[0],
            text: docStatus[1]
          }
          options.push(option)
        })
      }
      return options
    },
    uploadDocumentStatusPopoverOptions () {
      const options = []
      if (this.uploadDocumentStatusList && this.uploadDocumentStatusList.length > 0) {
        this.uploadDocumentStatusList.forEach((docStatus) => {
          if (docStatus[0] !== 'R') {
            const option = {
              value: docStatus[0],
              text: docStatus[1]
            }
            options.push(option)
          }
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getDocumentStatusChoices: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices
    }),
    selectDocStatus (item) {
      this.$set(this.form, 'status', item.value)
      this.$set(this.form, 'status_name', item.text)
    },
    clearDocStatus () {
      this.$set(this.form, 'status', null)
      this.$set(this.form, 'status_name', null)
    }
  }
}
</script>
