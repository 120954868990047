<template>
  <b-modal
    id="modalYardDocument"
    ref="modalYardDocument"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    :scrollable="true"
    size="lg"
    @hidden="modalYardDocumentHidden"
    hide-footer
    hide-header
    body-class="modal-document"
    content-class="modal-document"
  >
    <upload-document-form
      :id="yardDocumentId"
      :yard="$route.params.yardId"
      :doc-item="docItem"
      :showOrganization="false"
      :showDocument="false"
      :onFormSubmit="onSubmit"
      :onFormCancel="modalYardDocumentHidden"
    />
  </b-modal>
</template>

<script>
import UploadDocumentForm from './UploadDocumentForm.vue'
import { storeMixin } from '@/storeMixin'
import { mapActions } from 'vuex'

import { TYPES as UPLOAD_DOCUMENT_TYPES } from '@/pages/document/upload-document/store'

export default {
  name: 'UploadDocumentModal',
  props: {
    yardDocumentId: { default: undefined },
    docItem: { default: undefined },
    title: { required: true }
  },
  components: {
    'upload-document-form': UploadDocumentForm
  },
  mounted () {
    this.openModalYardDocument()
  },
  methods: {
    ...mapActions({
      partialUpdate: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS.partialUpdate
    }),
    openModalYardDocument () {
      this.$refs.modalYardDocument.show()
    },
    modalYardDocumentHidden () {
      this.$emit('close')
      this.$refs.modalYardDocument.hide()
    },
    onSubmit (formData) {
      formData.id = this.yardDocumentId
      formData = storeMixin.utils.parseFormMultipart(formData, ['attachment'])
      this.partialUpdate(formData).then(
        () => {
          this.modalYardDocumentHidden()
        }
      )
    }
  }
}
</script>
