<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_TYPES } from '../../../yard/yard/store'
import { vue } from '@/main'

export default {
  name: 'YardMixin',
  props: { organization: {} },
  created () {
    if (!this.$route.params.yardId) {
      this.setYardList({})
    }
  },
  computed: {
    ...mapGetters({
      yardList: YARD_TYPES.GENERIC.yard.yard.LIST.GETTERS.list
    }),
    yardListOptions () {
      if (this.yardList) {
        return this.prepareListForMultiSelect(this.yardList.results, 'id', ['code'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setYardList: YARD_TYPES.GENERIC.yard.yard.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getYardList: YARD_TYPES.GENERIC.yard.yard.LIST.ACTIONS.setFilters
    }),
    onSearchYard (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        const filters = {}
        filters.filter__code__icontains = search
        filters.order_by = '-status'
        this.getYardList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setYardList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    setYards (formData) {
      if (this.form.yards) {
        const list = []
        this.form.yards.forEach((row) => list.push(row.id))
        this.$set(formData, 'yards', list)
      } else {
        this.$set(formData, 'yards', [])
      }
      return formData
    },
    removeYard (item) {
      const yards = this.form.yards.filter((yard) => {
        return yard.id !== item.id
      })
      this.$set(this.form, 'yards', yards)
      if (this.form.yards.length === 0) this.$set(this.form, 'yards', null)
    },
    selectYard (item) {
      if (!this.form.yards) {
        this.$set(this.form, 'yards', [])
      }
      this.form.yards.push(item)
    }
  }
}
</script>
