<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.document"
        :label-form="'Document' | translate"
        label-search="text"
        class-form="col-xl-12 my-2"
        :disabled="!primaryDoc"
        :value="form.document ? { value: form.document, text: form.document_name } : null"
        :placeholder="$t('Select document')"
        :placeholder-field="form.document_name"
        :options="documentOptions"
        @search="onSearchDocument"
        @select="selectDocument"
        @clear="clearDocument"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-datetime
        :validator="$v.form.date_start"
        :label-form="'Validity start date' | translate"
        class-form="col-lg-4 my-2"
        labelColsLg="6"
        type="date"
        :value="date_start"
        :disabled="!primaryDoc || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'date_start')"
      />
      <form-thux-horizontal-datetime
        :validator="$v.form.date_end"
        :label-form="'Validity end date' | translate"
        class-form="col-lg-4 my-2"
        labelColsLg="6"
        type="date"
        :value="date_end"
        :min-date="form.date_start"
        :disabled="!primaryDoc || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'date_end')"
      />
      <b-btn-group class="col-xl-4 my-2 pl-2">
        <b-btn
          v-if="showAddButton"
          class="mr-1"
          variant="primary"
          :disabled="$v.form.$invalid || !primaryDoc"
          @click="addDocument">
          <i class="fas fa-plus"></i>
        </b-btn>
        <b-btn class="mr-1" variant="outline-primary" @click="removeDocument"
          :disabled="!primaryDoc">
          <i :class="index === 0 && otherDocsLength === 1 ? 'fas fa-eraser' : 'fas fa-minus'"></i>
        </b-btn>
    </b-btn-group>
    </b-form-row>
  </b-form>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { vue } from '@/main'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import DocumentMixin from '../mixins/DocumentMixin'
import { DOCUMENT_DOCUMENT_DPI_SUBTYPE, DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS } from '@/const'

export default {
  name: 'DocumentForm',
  mixins: [ThuxDetailPanelMixin, DocumentMixin],
  props: {
    docFilters: {},
    index: {},
    formUpdate: {},
    primaryDoc: { default: undefined },
    resetForm: { default: false },
    otherDocsLength: {},
    showAddButton: {},
    selectedStatus: { default: undefined }
  },
  watch: {
    resetForm () {
      if (this.resetForm) {
        this.$set(this, 'form', {})
        this.$set(this, 'date_start', null)
        this.$set(this, 'date_end', null)
      }
    },
    formUpdate: {
      handler () {
        if (this.formUpdate) {
          this.$set(this, 'form', this.formUpdate)
          if (this.form.date_start) {
            this.$set(this, 'date_start', moment(new Date(this.form.date_start)))
          }
          if (this.form.date_end) {
            this.$set(this, 'date_end', moment(new Date(this.form.date_end)))
          }
        }
      },
      deep: true
    },
    formInvalid () {
      this.$emit('doc-form-valid-change', this.formInvalid)
    }
  },
  data () {
    return {
      form: {},
      DOCUMENT_DOCUMENT_DPI_SUBTYPE,
      selectedDoc: undefined,
      date_start: null,
      date_end: null
    }
  },
  computed: {
    formInvalid () {
      return this.$v.form.$invalid
    }
  },
  methods: {
    init () {
      this.$watch('form', () => {
        this.$emit('edit-other-doc', this.form)
      }, { deep: true })
    },
    convertStringToDuration (string) {
      if (string) {
        const numberOfHours = string.split(':')[0]
        const totalHoursList = numberOfHours.split(' ')
        let days = 0
        let hours = 0
        if (totalHoursList.length === 2) {
          days = parseInt(totalHoursList[0].trim())
          hours = parseInt(totalHoursList[1].trim())
        } else {
          days = Math.floor(parseInt(numberOfHours) / 24)
          const remainder = numberOfHours % 24
          hours = Math.floor(remainder)
        }
        return { days: days, hours: hours }
      }
      return {}
    },
    dateChange (evt, dateKey) {
      const date = evt.date
      if (date) {
        const oldData = this.form.date_start
        this.$set(this.form, dateKey, date.format('YYYY-MM-DD'))
        if (dateKey === 'date_start' && oldData !== date.format('YYYY-MM-DD')) {
          if (dateKey === 'date_start' && this.selectedDoc && this.selectedDoc.validity_duration) {
            const duration = this.convertStringToDuration(this.selectedDoc.validity_duration)
            if (duration.days || duration.hours) {
              let endDate = date.add(duration.days, 'days')
              endDate = endDate.add(duration.hours, 'hours')
              this.$set(this, 'date_end', endDate)
              this.$set(this.form, 'date_end', endDate.format('YYYY-MM-DD'))
            }
          }
        }
      } else {
        this.$set(this.form, dateKey, null)
        this.$set(this, dateKey, undefined)
      }
    },
    onSearchDocument (search) {
      let filters = {}
      if (this.docFilters) {
        filters = this.docFilters
      }
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        filters.filter__name__icontains = search
        filters.exclude__subtype = 'DPI'
        this.getDocumentList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setDocumentList({})
      }
    },
    selectDocument (item) {
      this.$set(this.form, 'document', item.value)
      this.$set(this.form, 'document_name', item.text)
      this.selectedDoc = this.documentsList.results.find(el => el.id === item.value)
      this.$set(this.form, 'date_start_end', this.selectedDoc.date_start_end)
    },
    removeDocument () {
      if (this.index > 0 || this.otherDocsLength > 1) {
        this.$emit('remove-document', this.index)
      } else {
        this.$set(this, 'form', {})
        this.$set(this, 'date_start', undefined)
        this.$set(this, 'date_end', undefined)
        this.$set(this, 'selectedDoc', undefined)
        this.$emit('remove-document', this.index)
      }
    },
    addDocument () {
      this.$emit('add-document', this.$v.form.$model)
    }
  },
  validations () {
    const form = {
      document: {},
      date_start: {},
      date_end: {}
    }
    if (this.selectedDoc && this.selectedDoc.date_start_end && this.selectedStatus !== DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS) {
      form.date_start = { required }
      form.date_end = { required }
    }
    return { form: form }
  }
}
</script>
