<template>
  <panel :title="$t('Upload copy')" noButton="true">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-file
          :validator="$v.form.attachment"
          :label-form="'File' | translate"
          :class-form="`col-lg-12 mb-2 mt-2`"
          :value="form.attachment"
          :placeholder="$t('Choose a file or drop it here...')"
          :drop-placeholder="$t('Drop file here...')"
          @input="$set(form, 'attachment', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.status"
          :external-errors="errors['status']"
          :label-form="'Status' | translate"
          class-form="col-lg-12 mb-2"
          :disabled="!formEditable"
          label-search="text"
          :value="form.status ? { value: form.status, text: form.status_name } : null"
          :placeholder="$t('Select status')"
          :options="documentStatusOptions"
          @select="selectDocumentStatus"
          @clear="clearDocumentStatus"
        />
      </b-form-row>
      <b-form-row v-if="showOrganization">
        <form-thux-horizontal-select
          :validator="$v.form.organization"
          :external-errors="errors['organization']"
          :label-form="'Organization' | translate"
          label-search="text"
          class-form="col-lg-12 mb-2"
          :disabled="!formEditable || !organizationOptions.length"
          :value="form.organization ? { value: form.organization, text: form.organization_name } : null"
          :placeholder="$t('Select organization')"
          :options="organizationOptions"
          @select="selectOrganization"
          @clear="clearOrganization"
        />
      </b-form-row>
      <b-form-row v-if="showDocument">
        <form-thux-horizontal-select
          :validator="$v.form.document"
          :external-errors="errors['document']"
          :label-form="'Document' | translate"
          label-search="text"
          class-form="col-lg-12 mb-2"
          :disabled="!formEditable || !documentOptions.length"
          :value="form.document ? { value: form.document, text: form.document_name } : null"
          :placeholder="$t('Select document')"
          :options="documentOptions"
          @select="selectDocument"
          @clear="clearDocument"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.date_start"
          :external-errors="errors['date_start']"
          :label-form="'Validity start date' | translate"
          class-form="col-lg-6 mb-3"
          labelColsLg="4"
          type="date"
          :value="date_start"
          :placeholder="$t('Enter the date')"
          @change="dateChange($event, 'date_start')"
        />
        <form-thux-horizontal-datetime
          :validator="$v.form.date_end"
          :external-errors="errors['date_end']"
          :label-form="'Validity end date' | translate"
          class-form="col-lg-6 mb-3"
          labelColsLg="4"
          type="date"
          :value="date_end"
          :placeholder="$t('Enter the date')"
          @change="dateChange($event, 'date_end')"
        />
      </b-form-row>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="onCancelClick">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="onSubmitClick"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </b-form>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from './store'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard/yard-contractor/store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import OrganizationMixin from './mixins/OrganizationMixin'
import DocumentMixin from './mixins/DocumentMixin'
import DocumentStatusMixin from './mixins/DocumentStatusMixin'

export default {
  name: 'UploadDocument',
  props: {
    yard: { required: true },
    docItem: { required: true },
    showOrganization: { default: true },
    showDocument: { default: true },
    onFormSubmit: { default: false },
    onFormCancel: { default: false }
  },
  mixins: [ThuxDetailPanelMixin, OrganizationMixin, DocumentMixin, DocumentStatusMixin],
  data () {
    return {
      pageName: 'Document',
      date_start: null,
      date_end: null,
      form: {
        attachment: null,
        status: null,
        status_name: null
      }
    }
  },
  computed: {
    ...mapGetters({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.GETTERS,
      contractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.list,
      documentsList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.list,
      documentStatusChoicesList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices
    })
  },
  methods: {
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS,
      getContractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.setFilters,
      getDocumentStatusChoicesList:
        UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices
    }),
    downloadAttachment () {
      console.log('downloadAttachment', this.form.attachment)
      // this.download({ id: item.id, action: 'download-signed', simpleFileName: item.signed_filename })
    },
    init () {
      this.cleanErrors()
      this.getDocumentStatusChoicesList()
      this.getContractorsList({ filter__yard: this.$route.params.yardId, no_page: 'no_page' })
      this.initFields()
      if (this.id) {
        this.retrieve({ id: this.id })
      }
      this.makeFormEditable()
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        this.$emit('submit', { data: this.form })
      }
    },
    dateChange (evt, dateKey) {
      const date = evt.date
      if (date) {
        this.$set(this.form, dateKey, date.format('YYYY-MM-DD'))
        if (dateKey === 'date_start' && this.docItem && this.docItem.document_duration) {
          const dateEnd = date.add(parseFloat(this.docItem.document_duration), 'seconds')
          this.$set(this.form, 'date_end', dateEnd.format('YYYY-MM-DD'))
          this.$set(this, 'date_end', dateEnd)
        }
      } else {
        this.$set(this.form, dateKey, undefined)
        this.$set(this, dateKey, undefined)
      }
    },
    onCancelClick () {
      if (this.onFormCancel) {
        this.onFormCancel()
        return
      }
      if (this.formEditable) {
        this.cancel()
      }
      this.closeForm()
    },
    onSubmitClick () {
      if (this.onFormSubmit) {
        this.onFormSubmit(this.form)
        return
      }
      if (this.formEditable) {
        this.onSubmit()
        return
      }
      this.makeFormEditable()
    }
  },
  validations () {
    const form = {
      attachment: { required },
      date_start: { },
      date_end: { },
      status: { },
      status_name: { }
    }
    if (this.docItem.date_start_end) {
      form.date_start = { required }
      form.date_end = { required }
    }
    return { form: form }
  }
}
</script>
