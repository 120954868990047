<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../../yard/yard-contractor/store'
import { vue } from '../../../../main'

export default {
  name: 'OrganizationMixin',
  props: { yard: {} },
  created () {
    this.setContractorsList({})
  },
  computed: {
    ...mapGetters({
      contractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.list
    }),
    organizationOptions () {
      if (this.contractorsList && this.contractorsList.results) {
        return this.contractorsList.results.map(item => {
          return {
            value: item.contractor,
            text: item.contractor_name
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setContractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getContractorsList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.setFilters
    }),
    onSearchOrganization (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getContractorsList({ filter__contractor__name__icontains: search, filter__yard: this.yard }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setContractorsList({})
      }
    },
    selectOrganization (item) {
      this.$set(this.form, 'organization', item.value)
      this.$set(this.form, 'organization_name', item.text)
    },
    clearOrganization () {
      this.$set(this.form, 'organization', null)
      this.$set(this.form, 'organization_name', null)
    }
  }
}
</script>
