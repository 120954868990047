<template>
  <div>
    <panel :title="title" noButton="true">
      <div class="note note-light-primary" v-if="!editParams.onlyData">
        <div class="note-content">
          <h4><b>{{"INSTRUCTIONS" | translate}}</b></h4>
          <p>
            {{'In the case of loading for multiple labels, remember that:' | translate}}
          <ul>
            <li>
              <strong>{{'If neither the validity start date nor the end date are entered' | translate}}</strong>,  {{'all documents will be generated without a start and end validity date' | translate }}
            </li>
            <li>
              <strong>{{'If only the validity start date is entered' | translate}}</strong>,  {{'all documents will be generated with the validity start date entered. For documents with a duration defined in the configurator, the validity end date will be calculated automatically by the system' | translate }}
            </li>
            <li>
              <strong>{{'If both the validity start date and the validity end date are entered' | translate}}</strong>,  {{'all documents will be generated with the start date and end validity date equal to the values entered' | translate }}
            </li>
            <li>
              <strong>{{'If note in entered' | translate}}</strong>,  {{'all documents will be generated with note equal to the value entered' | translate }}
            </li>
          </ul>
        </p>
        </div>
      </div>
      <b-form>
        <b-form-row v-if="!editParams.onlyData">
          <form-thux-horizontal-autocomplete
            :validator="$v.form.status"
            :external-errors="errors['status']"
            :label-form="'Status' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            :disabled="!formEditable || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
            label-search="text"
            :value="form.status ? { value: form.status, text: form.status_name } : null"
            :placeholder="$t('Select status')"
            :options="documentStatusOptions"
            @select="selectDocumentStatus"
            @clear="clearDocumentStatus"
          />
          <form-thux-horizontal-file
            :validator="$v.form.attachment"
            :label-form="'Attachment' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            :value="form.attachment"
            :disabled="!formEditable || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
            :placeholder="$t('Choose a file or drop it here...')"
            :drop-placeholder="$t('Drop file here...')"
            @input="selectAttachment($event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.document"
            :external-errors="errors['document']"
            :label-form="'Document' | translate"
            label-search="text"
            class-form="col-lg-12 my-2"
            :disabled="id != undefined"
            :value="form.document ? { value: form.document, text: form.document_name } : null"
            :placeholder="$t('Select document')"
            :placeholder-field="form.document_name"
            :options="documentOptions"
            @search="onSearchDocument"
            @select="selectDocument"
            @clear="clearDocument"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-datetime
            :validator="$v.form.date_start"
            :external-errors="errors['date_start']"
            :label-form="'Validity start date' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            type="date"
            :value="date_start"
            :disabled="!formEditable || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_start')"
          />
          <form-thux-horizontal-datetime
            :validator="$v.form.date_end"
            :external-errors="errors['date_end']"
            :label-form="'Validity end date' | translate"
            class-form="col-lg-6 my-2"
            labelColsLg="4"
            type="date"
            :value="date_end"
            :min-date="form.date_start"
            :disabled="!formEditable || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
            :placeholder="$t('Enter the date')"
            @change="dateChange($event, 'date_end')"
          />
        </b-form-row>
        <other-document-manager
          v-if="!editParams.onlyData"
          :docFilters="docFilters"
          :primaryDoc="selectedDoc"
          :other-documents="otherDocumentsList"
          :selected-status="form.status"
          @update-other-docs="updateOtherDocuments"
          @update-document-validation='updateOtherDocsValidations'
        />
        <b-form-row v-if="!organization && !contact">
          <form-thux-horizontal-autocomplete
            :validator="$v.form.organization"
            :external-errors="errors['organization']"
            :label-form="'Organization' | translate"
            label-search="text"
            class-form="col-lg-12 my-2"
            :disabled="id != undefined && !editParams.onlyData"
            :value="form.organization ? { value: form.organization, text: form.organization_name } : null"
            :placeholder="$t('Select organization')"
            :options="organizationOptions"
            @search="onSearchOrganization"
            @select="selectOrganization"
            @clear="clearOrganization"
          />
        </b-form-row>
        <b-form-row v-if="contact">
          <form-thux-horizontal-multiselect
            :validator="$v.form.organization"
            :external-errors="errors[organization]"
            :label-form="'Organization' | translate"
            class-form="col-lg-12 mb-2"
            :disabled="id != undefined && !editParams.onlyData"
            label-search="text"
            :value="form.organization ? { value: form.organization, text: form.organization_name } : null"
            :placeholder="
              $t('Select organization')
            "
            :options="contactOrganizationOptions"
            @search="onSearchContactOrganization"
            @remove="clearContactOrganization"
            @select="selectContactOrganization"
            @clear="clearContactOrganization"
          />
        </b-form-row>
        <b-form-row v-if="detail.yard_name_list">
          <form-thux-horizontal-input
            :validator="$v.form.yards"
            :external-errors="errors['yards']"
            :label-form="'Yards' | translate"
            class-form="col-lg-12 mb-2"
            :disabled="true"
            :value="detail.yard_name_list"
          />
        </b-form-row>
        <b-form-row v-if="form.status === 'P'">
            <form-thux-horizontal-text-area
            :validator="$v.form.validation_note"
            :label-form="'Validation note' | translate"
            class-form="col-lg-12 my-2"
            rows="3"
            max-rows="6"
            :value="form.validation_note"
            :disabled="!formEditable || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
            @change="$set(form, 'validation_note', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-text-area
            :validator="$v.form.note"
            :external-errors="errors['note']"
            :label-form="'Note' | translate"
            class-form="col-lg-12 mb-2"
            :value="form.note"
            :disabled="!formEditable || (selectedDoc && selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE)"
            @change="$set(form, 'note', $event)"
          />
        </b-form-row>
          <!-- <b-form-row v-if="showDpi">
            <form-thux-horizontal-checkbox
              :validator="$v.form.dpi"
              v-for="dpi in dpiList" :key="dpi.id"
              :label-form="dpi.name"
              class-form="col-lg-4 my-2"
              label-cols-lg="10"
              label-cols-sm="10"
              :value="dpiForm[dpi.id]"
              :disabled='!formEditable'
              @change="setDpiChange($event, dpi.id)"
              />
          </b-form-row> -->
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            @click.prevent="onSubmit"
            :disabled="$v.form.$invalid || otherDocsInvalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </b-form>
    </panel>
      <WarningDialog
        v-if="showAlreadyExistModal"
        ref-id="modal-warning"
        :no-close-on-backdrop="true"
        confirm-btn-variant="primary"
        :confirm-btn-disabled="false"
        :title="$t('ERROR')"
        :body="alreadyExistMsg"
        :button-confirm-text="'OK' | translate"
        @confirm-dialog-close="closeModal"
      >
      </WarningDialog>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { vue } from '@/main'
import WarningDialog from '@/components/WarningDialog'
import { DOCUMENT_DOCUMENT_DPI_SUBTYPE, DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS } from '@/const'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from './store'
import { TYPES as DOCUMENT_YARD_LINK_TYPES } from '../document-yard-link/store'
import { TYPES as ORGANIZATION_TYPES } from '../../organization/organization/store'
import { TYPES as CONTACT_DPI_TYPES } from '../../organization/contact-dpi/store'
import { TYPES as DPI_TYPES } from '../../miscellaneous/dpi/store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import OrganizationMixin from './mixins/OrganizationMixin'
import DocumentMixin from './mixins/DocumentMixin'
import DocumentStatusMixin from './mixins/DocumentStatusMixin'
import YardMixin from './mixins/YardMixin'
import ContactOrganizationMixin from './mixins/ContactOrganizationMixin'
import OtherDocumentManager from './components/OtherDocumentManager'
import moment from 'moment'

export default {
  name: 'YardDocumentEdit',
  mixins: [ThuxDetailPanelMixin, YardMixin, OrganizationMixin, DocumentMixin, DocumentStatusMixin, ContactOrganizationMixin],
  props: {
    yard: {},
    organization: {},
    contact: {},
    editParams: {},
    id: {},
    docFilters: {}
  },
  components: {
    WarningDialog,
    OtherDocumentManager
  },
  watch: {
    selectedDoc: function () {
      if (this.contact) {
        if (this.selectedDoc && this.selectedDoc.subtype === 'DPI') {
          if (this.form.organization) {
            this.showDpi = true
            this.cntDpiSetFilters({
              filter__contact_id: this.contact,
              filter__organization_id: this.form.organization,
              no_page: 'no_page'
            }).then(
              () => {
                this.dpiList.forEach(el => {
                  const item = this.cntDpiList.find(item => item.dpi === el.id)
                  if (item) {
                    this.$set(this.dpiForm, el.id, true)
                  } else {
                    this.$set(this.dpiForm, el.id, false)
                  }
                })
              })
          } else {
            this.showDpi = false
            this.$set(this, 'dpiForm', {})
          }
        } else {
          this.showDpi = false
          this.$set(this, 'dpiForm', {})
        }
      } else {
        this.showDpi = false
        this.$set(this, 'dpiForm', {})
      }
    },
    'form.organization': function () {
      if (this.contact) {
        if (this.form.organization) {
          if (this.selectedDoc && this.selectedDoc.subtype === 'DPI') {
            this.showDpi = true
            this.cntDpiSetFilters({
              filter__contact_id: this.contact,
              filter__organization_id: this.form.organization,
              no_page: 'no_page'
            }).then(
              () => {
                this.dpiList.forEach(el => {
                  const item = this.cntDpiList.find(item => item.dpi === el.id)
                  if (item) {
                    this.$set(this.dpiForm, el.id, true)
                  } else {
                    this.$set(this.dpiForm, el.id, false)
                  }
                })
              })
          } else {
            this.showDpi = false
            this.$set(this, 'dpiForm', {})
          }
        } else {
          this.showDpi = false
          this.$set(this, 'dpiForm', {})
        }
      } else {
        this.showDpi = false
        this.$set(this, 'dpiForm', {})
      }
    }
  },
  data () {
    return {
      pageName: 'Document',
      date_start: null,
      date_end: null,
      form: {
        attachment: null,
        status: null,
        status_name: null
      },
      dpiForm: { },
      selectedDoc: undefined,
      showDpi: false,
      alreadyExistMsg: undefined,
      showAlreadyExistModal: false,
      DOCUMENT_DOCUMENT_DPI_SUBTYPE,
      validStatusOptions: [],
      otherDocumentsList: undefined,
      otherDocsValidation: []
    }
  },
  computed: {
    ...mapGetters({
      uploadDocFilters: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.filters,
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.GETTERS,
      dpiList: DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.GETTERS.list,
      cntDpiList: CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.LIST.GETTERS.list,
      orgList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list
    }),
    organizationOptions () {
      if (this.contact) {
        if (this.orgList && this.orgList.results) {
          return this.orgList.results.map(item => {
            return {
              value: item.id,
              text: item.name
            }
          })
        }
        return []
      }
      if (this.contractorsList && this.contractorsList.results) {
        return this.contractorsList.results.map(item => {
          return {
            value: item.contractor,
            text: item.contractor_name
          }
        })
      }
      return []
    },
    otherDocsInvalid () {
      const invalid = this.otherDocsValidation.find(el => el === true)
      if (invalid) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations({
      setDetail: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS,
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.DETAIL.ACTIONS,
      setDpiFilters: DPI_TYPES.GENERIC.miscellaneous.dpi.LIST.ACTIONS.setFilters,
      cntDpiSetFilters: CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.LIST.ACTIONS.setFilters,
      cntDpiCreate: CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.DETAIL.ACTIONS.create,
      cntDpiUpdate: CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.DETAIL.ACTIONS.update,
      cntDpiDelete: CONTACT_DPI_TYPES.GENERIC.organization.contactdpi.DETAIL.ACTIONS.delete,
      createYardLink: DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.DETAIL.ACTIONS.create,
      setOrgFilters: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters
    }),
    init () {
      this.cleanErrors()
      this.setDetail({})
      const now = moment()
      console.log(now.month())
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.$set(this, 'form', this.detail)
            if (this.detail.date_start) {
              this.$set(this, 'date_start', moment(new Date(this.detail.date_start)))
            }
            if (this.detail.date_end) {
              this.$set(this, 'date_end', moment(new Date(this.detail.date_end)))
            }
            this.getDocumentList({ filter__id: this.form.document }).then(
              () => {
                this.selectedDoc = this.documentsList.results.find(el => el.id === this.form.document)
              }
            )
          }
        )
      } else {
        if (this.organization) {
          this.setOrgFilters({ filter__id: this.organization }).then(
            () => {
              this.$set(this.form, 'organization', this.orgList.results[0].id)
              this.$set(this.form, 'organization_name', this.orgList.results[0].name)
            }
          )
        }
      }
      this.setDpiFilters({ filter__status: 1, no_page: 'no_page', order_by__name: 'name' })
      this.makeFormEditable()
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        const formData = Object.assign({}, this.form)
        if (formData.note === undefined) {
          formData.note = ''
        }
        if (formData.validation_note === undefined) {
          formData.validation_note = ''
        }
        if (this.yard) {
          this.$set(formData, 'yard', this.yard)
        } else if (this.organization && !this.contact) {
          this.$set(formData, 'organization', this.organization)
        } else if (this.contact) {
          this.$set(formData, 'worker', this.contact)
          if (!formData.organization) {
            delete formData.organization
          }
        }
        if (this.otherDocumentsList && this.otherDocumentsList.length > 0) {
          formData.other_documents = JSON.stringify(this.otherDocumentsList)
        }
        if (this.editParams.onlyData) {
          delete formData.attachment
          if (!formData.organization) {
            formData.organization = null
          }
          this.update(formData).then(() => {
            // this.getList()
            this.$emit('on-submit')
          })
        } else {
          const checkFilters = this.getCheckFlters(formData)
          console.log(formData)
          console.log(checkFilters)
          if (!this.id) {
            this.checkExistingDocument(checkFilters).then(
              (resp) => {
                if (resp.data.length > 0) {
                  const docNames = resp.data.map(a => a.document_name)
                  this.$set(
                    this,
                    'alreadyExistMsg',
                  `${this.$t('This documents already exists')}: ${docNames.join(', ')}.<br /> ${this.$t('Please remove them from form data. Then search them into the list and change them using action buttons')}.`
                  )
                  this.$set(this, 'showAlreadyExistModal', true)
                } else {
                  this.bulkCreate(formData).then(() => {
                    if (formData.yards && formData.yards.length > 0) {
                      formData.yards.forEach((yard) => {
                        const promises = []
                        promises.push(this.createYardLink({ upload_document: this.detail.id, yard: yard, is_link_valid: true }))
                        Promise.all(promises).then(() => {
                          // this.getList()
                          this.$emit('on-submit')
                        })
                      })
                    } else {
                      this.$emit('on-submit')
                    }
                  })
                }
              })
          } else {
            if (formData.other_documents) {
              this.checkExistingDocument(checkFilters).then(
                (resp) => {
                  if (resp.data.length > 0) {
                    const docNames = resp.data.map(a => a.document_name)
                    this.$set(
                      this,
                      'alreadyExistMsg',
                      `${this.$t('This documents already exists')}: ${docNames.join(', ')}.<br /> ${this.$t('Please remove them from form data. Then search them into the list and change them using action buttons')}.`
                    )
                    this.$set(this, 'showAlreadyExistModal', true)
                  } else {
                    this.bulkUpdate(formData).then(() => {
                      // this.getList()
                      this.$emit('on-submit')
                    })
                  }
                })
            } else {
              this.bulkUpdate(formData).then(() => {
                // this.getList()
                this.$emit('on-submit')
              })
            }
          }
        }
      }
    },
    getCheckFlters (formData) {
      const checkFilters = {
        filter__is_passed: 'False'
      }
      if (formData.worker) {
        checkFilters.filter__worker = formData.worker
      } else {
        checkFilters.filter__worker__isnull = true
      }
      if (formData.organization) {
        checkFilters.filter__organization = formData.organization
      } else {
        checkFilters.filter__organization__isnull = true
      }
      if (formData.yard) {
        checkFilters.filter__yard_documents__yard__id = formData.yard
        checkFilters.filter__yard_documents__is_link_valid = 'True'
      } else {
        checkFilters.filter__yard_documents__yard__id = null
        checkFilters.filter__yard_documents__is_link_valid = null
      }
      const documents = []
      if (!this.id) {
        documents.push(formData.document)
      }
      if (this.otherDocumentsList && this.otherDocumentsList.length > 0) {
        this.otherDocumentsList.forEach(el => documents.push(el.document))
      }
      if (documents.length > 0) {
        checkFilters.filter__document__in = documents
      }
      if (formData.yard && formData.other_documents) {
        checkFilters.filter__yard_documents__yard_id = formData.yard
      }

      // TODO check document yards
      return checkFilters
    },
    dateChange (evt, dateKey) {
      const date = evt.date
      if (date) {
        const oldData = this.form.date_start
        this.$set(this.form, dateKey, date.format('YYYY-MM-DD'))
        if (dateKey === 'date_start' && oldData !== date.format('YYYY-MM-DD')) {
          if (dateKey === 'date_start' && this.selectedDoc && this.selectedDoc.validity_duration) {
            const duration = this.convertStringToDuration(this.selectedDoc.validity_duration)
            if (duration.days || duration.hours) {
              let endDate = date.add(duration.days, 'days')
              endDate = endDate.add(duration.hours, 'hours')
              this.$set(this, 'date_end', endDate)
              this.$set(this.form, 'date_end', endDate.format('YYYY-MM-DD'))
            }
          } else if (dateKey === 'date_start' && this.detail.document_duration) {
            const endDate = date.add(this.detail.document_duration, 'seconds')
            this.$set(this, 'date_end', endDate)
            this.$set(this.form, 'date_end', endDate.format('YYYY-MM-DD'))
          }
        }
      } else {
        this.$set(this.form, dateKey, null)
        this.$set(this, dateKey, undefined)
      }
    },
    selectDocument (item) {
      this.$set(this.form, 'document', item.value)
      this.$set(this.form, 'document_name', item.text)
      this.selectedDoc = this.documentsList.results.find(el => el.id === item.value)
      if (this.selectedDoc.subtype === DOCUMENT_DOCUMENT_DPI_SUBTYPE) {
        const statusEl = this.documentStatusOptions.find(el => el.value === 'R')
        this.$set(this.form, 'status', statusEl.value)
        this.$set(this.form, 'status_name', statusEl.text)
        delete this.form.attachment
        delete this.form.documents
        delete this.form.date_start
        delete this.form.date_end
        this.$set(this, 'date_start', undefined)
        this.$set(this, 'date_end', undefined)
        delete this.form.validation_note
        delete this.form.note
      }
    },
    clearDocument () {
      this.$set(this.form, 'document', null)
      this.$set(this.form, 'document_name', null)
      this.$set(this, 'selectedDoc', null)
    },
    convertStringToDuration (string) {
      if (string) {
        const numberOfHours = string.split(':')[0]
        const totalHoursList = numberOfHours.split(' ')
        let days = 0
        let hours = 0
        if (totalHoursList.length === 2) {
          days = parseInt(totalHoursList[0].trim())
          hours = parseInt(totalHoursList[1].trim())
        } else {
          days = Math.floor(parseInt(numberOfHours) / 24)
          const remainder = numberOfHours % 24
          hours = Math.floor(remainder)
        }
        return { days: days, hours: hours }
      }
      return {}
    },
    onSearchOrganization (search) {
      if (this.contact) {
        if (search.length >= 2) {
          vue.$store.commit('componentLoading', false)
          this.setOrgFilters({ filter__status: 1, filter__contacts: this.contact, filter__name__icontains: search }).then(() => {
            vue.$store.commit('componentLoading', true)
          })
        }
      } else {
        if (search.length >= 2) {
          vue.$store.commit('componentLoading', false)
          this.getContractorsList({ filter__contractor__name__icontains: search, filter__yard: this.yard }).then(() => {
            vue.$store.commit('componentLoading', true)
          })
        } else {
          this.setContractorsList({})
        }
      }
    },
    // setDpiChange (evt, dpiId) {
    //   this.$set(this.dpiForm, dpiId, evt)
    // },
    // saveDpi () {
    //   const promises = []
    //   Object.keys(this.dpiForm).forEach(dpiId => {
    //     const item = this.cntDpiList.find(el => el.dpi === parseInt(dpiId))
    //     if (!item) {
    //       if (this.dpiForm[dpiId]) {
    //         promises.push(this.cntDpiCreate({
    //           organization: this.form.organization,
    //           contact: this.contact,
    //           dpi: dpiId,
    //           date_delivery: this.form.date_start
    //         }))
    //       }
    //     } else {
    //       if (this.dpiForm[dpiId]) {
    //         promises.push(this.cntDpiUpdate({
    //           id: item.id,
    //           organization: this.form.organization,
    //           contact: this.contact,
    //           dpi: dpiId,
    //           date_delivery: this.form.date_start

    //         }))
    //       } else {
    //         promises.push(this.cntDpiDelete({
    //           id: item.id
    //         }))
    //       }
    //     }
    //   })
    //   Promise.all(promises).then(
    //     this.makeFormReadonly()
    //   )
    // },
    onSearchDocument (search) {
      let filters = {}
      if (this.docFilters) {
        filters = this.docFilters
      }
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        filters.filter__name__icontains = search
        // filters.exclude__subtype = 'DPI'
        this.getDocumentList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setDocumentList({})
      }
    },
    closeModal () {
      this.showAlreadyExistModal = false
    },
    selectAttachment (evt) {
      this.$set(this.form, 'attachment', evt)
      if (evt) {
        if (this.form.status === DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS) {
          this.$set(this.form, 'status', undefined)
        }
        this.$set(this, 'validStatusOptions', [])
        this.documentStatusOptions.forEach(element => {
          if (element.value !== DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS) {
            this.validStatusOptions.push(element.value)
          }
        })
      } else {
        this.$set(this, 'validStatusOptions', [])
        this.documentStatusOptions.forEach(element => {
          this.validStatusOptions.push(element.value)
        })
      }
    },
    updateOtherDocuments (documents) {
      this.$set(this, 'otherDocumentsList', documents)
    },
    updateOtherDocsValidations (documentsInvalid) {
      this.$set(this, 'otherDocsValidation', documentsInvalid)
    }
  },
  validations () {
    const validator = {
      form: {
        status: { required },
        attachment: { required },
        document: { required },
        documents: { },
        organization: { required },
        date_start: { },
        date_end: { },
        status_name: { },
        dpi: { },
        validation_note: { },
        note: { }
      },
      otherDocumentsList: {
        $each: {
          document: {},
          date_start: {},
          date_end: {}
        }
      }
    }
    if (this.organization) {
      validator.form.organization = {}
    }
    if (this.form.status === 'R') {
      validator.form.attachment = {}
    }
    if (this.contact) {
      validator.form.organization = { }
    }
    if (this.selectedDoc && this.selectedDoc.subtype === 'DPI') {
      validator.form.organization = { required }
    }
    if (this.selectedDoc && this.selectedDoc.date_start_end && this.form.status !== DOCUMENT_UPLOAD_DOCUMENT_REQUESTED_STATUS) {
      validator.form.date_start = { required }
      validator.form.date_end = { required }
    }
    if (!this.editParams.onlyData) {
      validator.form.status = {
        required,
        validStatus: (value) => {
          return this.validStatusOptions.includes(value)
        }
      }
    } else {
      validator.form.status = { required }
    }
    return validator
  }
}
</script>
