<template>
  <div class="wrapper">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed panel-with-actions">
        <div class="panel-header">
          <h1 v-if="$route.params.yardId" class="page-header mr-2 d-flex align-items-center">
            {{$t("Yard's documents") }} &nbsp;
            <span v-if="yardDetail">
              {{yardDetail.name ? yardDetail.name: yardDetail.customer_name}} ({{yardDetail.code}})
            </span>
            <icon-active-filters
              v-if="activeFilters"
              :filters="{}"
            />
            <div class="page-header-actions ml-2 mr-4" v-if="yardDetail.is_active">
              <b-btn
                variant="outline-primary"
                v-has-perms="generateYardDocumentsRolePerm"
                :title="'Generate documents requests' | translate"
                @click.prevent="bulkGenerateYardDocuments(yardDetail.id)"
              >
                <i class="fas fa-folder-plus"></i>
              </b-btn>
            </div>
          </h1>
          <h1 v-else-if="$route.params.cntId" class="page-header mr-2 d-flex align-items-center">
            {{ $t("Contact's documents") }}{{ contactName ? `, ${contactName}` : '' }}
            <icon-active-filters
              v-if="activeFilters"
              :filters="{}"
            />
          </h1>
          <h1 v-else-if="$route.params.orgId" class="page-header mr-2 d-flex align-items-center">
            {{ $t("Organization's documents") }}{{ organizationName ? `, ${organizationName}` : '' }}
            <icon-active-filters
              v-if="activeFilters"
              :filters="{}"
            />
          </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li v-for="(breadcrumb) in breadcrumbs" :key="breadcrumb.label" class="breadcrumb-item">
              <router-link :to="breadcrumb.route">
                {{ breadcrumb.label | translate }}
              </router-link>
            </li>
            <li class="breadcrumb-item">{{ title | translate }}</li>
          </ol>
        </div>
        <component-edit
          v-if="showEditForm && editComponent"
          :yard="$route.params.yardId"
          :organization="$route.params.orgId"
          :contact="$route.params.cntId"
          :editParams="editParams"
          :id="componentId"
          @on-submit='refreshListAfterSubmit'
          @close-form='closeEditForm'
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="title" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :yard-detail="yardDetail"
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @refresh-list="refreshListAfterSubmit"
              />
            </template>
          </thux-list-component>
        </panel>
        <document-to-download-panel v-if="$route.params.yardId" :yardId="$route.params.yardId"> </document-to-download-panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters && showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters :yard="$route.params.yardId" :cntId="$route.params.cntId" :organization="this.$route.params.orgId"/>
    </portal-target>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ROUTES as YARD_ROUTES } from '../../yard/yard/router'
import { YARD_WORK_STATUSES } from '@/const'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import UploadDocumentsTable from './UploadDocumentsTable'
import UploadDocumentEdit from './UploadDocumentEdit'
import UploadDocumentCommonFilters from './UploadDocumentCommonFilters'
import DocumentToDownloadPanel from '../components/DocumentToDownload/DocumentToDownloadPanel'
import { TYPES as YARD_TYPES } from '../../yard/yard/store'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from './store'
import { TYPES as CONTACT_TYPES } from '../../organization/contact/store'
import { ROUTES as OWNER_ORG_ROUTES } from '../../organization/organization/organization/owner-organization/router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../../organization/organization/organization/supplier-organization/router'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../organization/organization/organization/customer-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../../organization/organization/organization/lead-organization/router'
import { ROUTES as SUPPLIER_CNT_ROUTES } from '../../organization/contact/contact/supplier-contact/router'
import { ROUTES as CUSTOMER_CNT_ROUTES } from '../../organization/contact/contact/customer-contact/router'
import { ROUTES as LEAD_CNT_ROUTES } from '../../organization/contact/contact/lead-contact/router'
import { ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_OWNER_CODE } from '../../../const'

export default {
  name: 'YardDocumentsList',
  props: { orgName: {}, orgType: {}, cntName: {} },
  mixins: [ThuxListMixin],
  components: {
    'common-filters': UploadDocumentCommonFilters,
    'component-table': UploadDocumentsTable,
    'component-edit': UploadDocumentEdit,
    'document-to-download-panel': DocumentToDownloadPanel
  },
  mounted () {
    this.setYardDetail({})
    if (this.$route.params.yardId) {
      this.yardRetrieve({ id: this.$route.params.yardId })
    }
  },
  data () {
    const optionalChoices = [{
      value: 'True',
      text: this.$t('Optional')
    },
    {
      value: 'False',
      text: this.$t('Not Optional')
    }]
    return {
      YARD_ROUTES,
      SUPPLIER_ORG_ROUTES,
      organizationName: null,
      contactName: '',
      YARD_WORK_STATUSES,
      title: this.$t('Documents'),
      rolePerm: ['document_uploaddocument_list'],
      actionEnablePermission: ['document_uploaddocument_enable'],
      actionDisablePermission: ['document_uploaddocument_disable'],
      breadcrumbs: [],
      searchFields: [
        { key: 'filter__is_optional', placeholder: this.$t('Is Optional'), type: 'select', col: 6, options: optionalChoices },
        { key: 'filter__document__name__icontains', placeholder: this.$t('Document name'), type: 'text', col: 6 },
        { key: 'filter__organization__name__icontains', placeholder: this.$t('Organization name'), type: 'text', col: 6 },
        { key: 'filter__date_start__gte', placeholder: this.$t('Validity start greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_end__lte', placeholder: this.$t('Validity end less than equal'), type: 'date', col: 6 }
      ],
      showCommonFilters: false,
      // Permissions
      generateYardDocumentsRolePerm: 'yard_yard_generate_documents'
    }
  },
  computed: {
    ...mapGetters({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS,
      yardDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS.detail,
      contactDetail: CONTACT_TYPES.GENERIC.organization.contact.DETAIL.GETTERS.detail
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return hasPerm('document_uploaddocument_create')
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.MUTATIONS.setOrderBy,
      setSelectedList: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.MUTATIONS.setSelectedList,
      setSelectAll: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.MUTATIONS.setSelectAll,
      setYardDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.MUTATIONS.setDetail,
      ocumentStatusChoices: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.documentStatusChoices
    }),
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS,
      yardRetrieve: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.retrieve,
      generateYardDocuments: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.generateYardDocuments,
      getContactDetail: CONTACT_TYPES.GENERIC.organization.contact.DETAIL.ACTIONS.retrieve,
      getDocumentstatuschoices: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS.getDocumentstatuschoices

    }),
    getComponentList () {
      this.removeFiltersToInstance()
      this.getDocumentstatuschoices().then(
        () => {
          const filterVals = []
          this.documentStatusChoices.forEach(el => {
            filterVals.push({
              value: el[0],
              text: el[1]
            })
          })
          this.searchFields.unshift({
            key: 'filter__status', placeholder: this.$t('Status'), type: 'select', col: 6, options: filterVals

          })
        }
      )
    },
    refreshListAfterSubmit () {
      this.getList()
      this.closeEditForm()
    },
    closeEditForm () {
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'showEditForm', false)
    },
    removeFiltersToInstance () {
      const filters = { }
      if (this.$route.params.yardId) {
        filters.filter__yard_documents__yard__id = this.$route.params.yardId
        filters.filter__yard_documents__is_link_valid = 'True'
        this.$set(this, 'breadcrumbs', [
          {
            label: 'Yards',
            route: { name: YARD_ROUTES.YARD_LIST }
          },
          {
            label: 'Yard',
            route: {
              name: YARD_ROUTES.YARD_DETAIL,
              params: { id: this.$route.params.yardId }
            }
          }
        ])
      } else if (this.$route.params.orgId) {
        filters.filter__organization__id = this.$route.params.orgId
        filters.filter__is_passed = 'False'
        filters.filter__worker__isnull = 'True'
        console.log(this.orgType)
        if (this.orgType === ORGANIZATION_OWNER_CODE) {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Organization',
              route: {
                name: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_DETAIL,
                params: {
                  id: this.$route.params.orgId
                }
              }
            }
          ])
          if (this.$route.params.cntId) {
            this.breadcrumbs.push({
              label: 'Contact',
              route: {
                name: OWNER_ORG_ROUTES.OWNER_ORGANIZATION_CONTACT_DETAIL,
                params: {
                  id: this.$route.params.orgId,
                  cntId: this.$route.params.cntId
                }
              }
            })
          }
        } else if (this.orgType === ORGANIZATION_SUPPLIER_CODE) {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Organizations',
              route: {
                name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_LIST,
                // : CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST,
                params: { orgType: ORGANIZATION_SUPPLIER_CODE }
              }
            },
            {
              label: 'Organization',
              route: {
                name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL,
                params: {
                  orgType: ORGANIZATION_SUPPLIER_CODE,
                  id: this.$route.params.orgId
                }
              }
            }
          ])
          if (this.$route.params.cntId) {
            this.breadcrumbs.push({
              label: 'Contact',
              route: {
                name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_CONTACT_DETAIL,
                params: {
                  orgType: ORGANIZATION_SUPPLIER_CODE,
                  id: this.$route.params.orgId,
                  cntId: this.$route.params.cntId
                }
              }
            })
          }
        } else if (this.orgType === ORGANIZATION_CUSTOMER_LEAD_CODE) {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Organizations',
              route: {
                name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_LIST,
                params: { orgType: ORGANIZATION_CUSTOMER_LEAD_CODE }
              }
            },
            {
              label: 'Organization',
              route: {
                name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL,
                params: {
                  orgType: ORGANIZATION_CUSTOMER_LEAD_CODE,
                  id: this.$route.params.orgId
                }
              }
            }
          ])
          if (this.$route.params.cntId) {
            this.breadcrumbs.push({
              label: 'Contact',
              route: {
                name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_CONTACT_DETAIL,
                params: {
                  orgType: ORGANIZATION_CUSTOMER_LEAD_CODE,
                  id: this.$route.params.orgId,
                  cntId: this.$route.params.cntId
                }
              }
            })
          }
        } else {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Organizations',
              route: {
                name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST,
                params: { orgType: ORGANIZATION_CUSTOMER_CODE }
              }
            },
            {
              label: 'Organization',
              route: {
                name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL,
                params: {
                  orgType: ORGANIZATION_CUSTOMER_CODE,
                  id: this.$route.params.orgId
                }
              }
            }
          ])
          if (this.$route.params.cntId) {
            this.breadcrumbs.push({
              label: 'Contact',
              route: {
                name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_CONTACT_DETAIL,
                params: {
                  orgType: ORGANIZATION_CUSTOMER_CODE,
                  id: this.$route.params.orgId,
                  cntId: this.$route.params.cntId
                }
              }
            })
          }
        }
        if (this.$route.params.cntId) {
          delete filters.filter__yard_documents__is_link_valid
          delete filters.filter__organization__id
          if (this.$route.params.orgId) {
            filters.organization_id_or_null = this.$route.params.orgId
          }
          delete filters.filter__worker__isnull
          filters.filter__worker__id = this.$route.params.cntId
        }
      } else if (this.$route.params.cntId) {
        filters.filter__worker__id = this.$route.params.cntId
        filters.filter__is_passed = 'False'
        if (this.orgType === ORGANIZATION_SUPPLIER_CODE) {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Contacts',
              route: {
                name: SUPPLIER_CNT_ROUTES.SUPPLIER_CONTACT_LIST,
                params: { orgType: ORGANIZATION_SUPPLIER_CODE }
              }
            },
            {
              label: 'Contact',
              route: {
                name: SUPPLIER_CNT_ROUTES.SUPPLIER_CONTACT_DETAIL,
                params: {
                  orgType: ORGANIZATION_SUPPLIER_CODE,
                  cntId: this.$route.params.cntId
                }
              }
            }
          ])
        } else if (this.orgType === ORGANIZATION_CUSTOMER_LEAD_CODE) {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Contacts',
              route: {
                name: LEAD_CNT_ROUTES.LEAD_CONTACT_LIST,
                params: { orgType: ORGANIZATION_CUSTOMER_LEAD_CODE }
              }
            },
            {
              label: 'Contact',
              route: {
                name: LEAD_CNT_ROUTES.LEAD_CONTACT_DETAIL,
                params: {
                  orgType: ORGANIZATION_CUSTOMER_LEAD_CODE,
                  cntId: this.$route.params.cntId
                }
              }
            }
          ])
        } else {
          this.$set(this, 'breadcrumbs', [
            {
              label: 'Contacts',
              route: {
                name: CUSTOMER_CNT_ROUTES.CUSTOMER_CONTACT_LIST,
                params: { orgType: ORGANIZATION_CUSTOMER_CODE }
              }
            },
            {
              label: 'Contact',
              route: {
                name: CUSTOMER_CNT_ROUTES.CUSTOMER_CONTACT_DETAIL,
                params: {
                  orgType: ORGANIZATION_CUSTOMER_CODE,
                  cntId: this.$route.params.cntId
                }
              }
            }
          ])
        }
      }
      this.setOrderBy('document__name')
      this.setFilters(filters).then(() => {
        try {
          this.$set(this, 'organizationName', this.list.results[0].organization_name)
        } catch {
          this.$set(this, 'organizationName', this.orgName)
        }
      })
      if (this.$route.params.cntId) {
        this.getContactDetail({ id: this.$route.params.cntId }).then(() => {
          try {
            this.$set(this, 'contactName', `${this.contactDetail.last_name} ${this.contactDetail.first_name}`)
          } catch {
            this.$set(this, 'contactName', '')
          }
        })
      }
    },
    bulkGenerateYardDocuments (yardId) {
      this.generateYardDocuments(yardId).then(
        () => {
          this.getList()
        }
      )
    },
    openEditForm (item) {
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'componentId', undefined)
      this.$set(this, 'editParams', {})
      if (item) {
        this.$set(this, 'componentId', item.id)
        this.$set(this, 'editParams', { onlyData: item.onlyData })
      }

      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
