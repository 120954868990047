<template>
  <div v-if="id" class="d-flex">
    <div class="btn-add-worker">
      <v-popover
        offset="16"
        trigger="click"
        placement="top"
        :open="openPopover"
        :auto-hide="false"
      >
        <slot name="body">
          <span

            v-if="docStatus"
            :class="`tag-link-item fw-600 tag tag-${DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS[docStatus]}`"
            :title="title"
            @click.prevent="$emit('show-popover-modal')"
          >
            {{ docStatusName }}
          </span>
        </slot>
        <template v-if="openPopover" slot="popover">
          <div class="popover-field">
            <div class="popover-header">
              {{ `${$t('Edit')} ${$t(fieldName)}` }}
            </div>
              <form-group-thux
                :label="'Status' | translate"
                class="col-12 mb-3 p-0"
                :validator="$v.form.status"
              >
                <multiselect-thux
                  label="text"
                  attributetype="select"
                  :close-on-select="true"
                  :value="form.status ? { value: form.status, text: form.status_name } : null"
                  :options="uploadDocumentStatusPopoverOptions"
                  :placeholder="$t('Select an option')
                  "
                  @select="selectDocStatus"
                  @clear="clearDocStatus"
                >
                </multiselect-thux>
              </form-group-thux>
              <form-group-thux
                v-if="form.status === 'P'"
                :label="'Validation note' | translate"
                class="col-12 mb-3 p-0"
                :validator="$v.form.validation_note"
              >
                <b-form-textarea
                  v-model="form.validation_note"
                  type="text"
                  :rows="3"
                  :max-rows="6"
                />
              </form-group-thux>

            <div class="popover-footer">
              <b-btn
                class="btn btn-outline-secondary"
                @click.prevent="$emit('close')"
              >
                {{ 'Cancel' | translate }}
              </b-btn>
              <b-btn
                :disabled="$v.$invalid"
                class="btn btn-primary"
                @click.prevent="$emit('change-status', form)"
              >
                <span >{{ 'Edit' | translate }}</span>
              </b-btn>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import docStatusMixin from '../mixins/docStatusMixin'
import MultiselectThux from '@/components/MultiselectThux'
import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS } from '@/const'

export default {
  name: 'UploadDocumentStatusFieldPopover',
  mixins: [docStatusMixin],
  props: {
    placement: {},
    openPopover: {
      required: true
    },
    instance: {
      required: true
    },
    closeOnSelect: {
      default: false
    },
    id: {
      required: true
    },
    fieldName: {
      required: true
    },
    thereAreFields: {
      required: true
    },
    disabledButton: {},
    docStatus: { },
    docStatusName: { },
    docValidationNote: {},
    btnTitle: {}
  },
  watch: {
    openPopover () {
      this.init()
    }
  },
  components: {
    'multiselect-thux': MultiselectThux
  },
  data () {
    return {
      form: {
        status: {}
      },
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS
    }
  },
  created () {
    this.init()
  },
  computed: {
    title () {
      if (this.btnTitle) {
        return this.btnTitle
      }
      return this.$t('{action} assigned by', { action: this.$t('Add') })
    }
  },
  methods: {
    init () {
      this.$set(this, 'form', { status: {} })
      if (this.docStatus) {
        this.$set(this.form, 'status', this.docStatus)
        this.$set(this.form, 'status_name', this.docStatusName)
      }
      if (this.docValidationNote) {
        this.$set(this.form, 'validation_note', this.docValidationNote)
      }
    }
  },
  validations () {
    const vals = {
      form: {
        status: { required },
        validation_note: { }
      }
    }
    return vals
  }
}
</script>
