<template>
  <b-modal
    id="documentUploadYardModal"
    ref="documentUploadYardModal"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    :scrollable="true"
    size="xl"
    @hidden="modalDocumentUploadYardHidden"
  >
    <div slot="modal-title">
      {{ title }}
      <span class="ml-2">-</span>
      <span class="font-weight-bold ml-2">{{ `${this.uploadDocumentName} - ${this.uploadOrganizationName}` }}</span>
    </div>
    <UploadDocumentYardList :upload-document-id="uploadDocumentId "/>
    <div slot="modal-footer">
      <b-btn class="mr-2" variant="primary" @click="modalDocumentUploadYardHidden">
        {{ 'OK' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import UploadDocumentYardList from './UploadDocumentYardList'

export default {
  name: 'DocumentUploadYardModal',
  components: { UploadDocumentYardList },
  props: {
    uploadDocumentId: { required: true },
    uploadDocumentName: { required: true },
    uploadOrganizationName: { required: true }
  },
  mounted () {
    this.openDocumentUploadYardModal()
  },
  computed: {
    title () {
      return `${this.$t('Yards')} ${this.$t('Document')}`
    }
  },
  methods: {
    // MODAL
    openDocumentUploadYardModal () {
      this.$refs.documentUploadYardModal.show()
    },
    modalDocumentUploadYardHidden () {
      this.$emit('close')
      this.$refs.documentUploadYardModal.hide()
    },
    onSubmit () {
      this.$emit('submit', this.form.file)
      this.modalDocumentUploadYardHidden()
    }
  }
}
</script>
