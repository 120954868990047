<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_YARD_LINK_TYPES } from '../../document-yard-link/store'
import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS } from '@/const'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'UploadDocumentYardTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS,
      fields: [
        {
          key: 'yard__code',
          label: this.$t('Code'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          condition: 'yard',
          sortable: true,
          value: 'code'
        },
        {
          key: 'yard__description',
          label: this.$t('Description'),
          condition: 'yard',
          sortable: true,
          value: 'description'
        },
        {
          key: 'yard__date_start',
          label: this.$t('Date start'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          condition: 'yard',
          sortable: true,
          value: 'date_start'
        },
        {
          key: 'yard__date_end',
          label: this.$t('Date end'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          condition: 'yard',
          sortable: true,
          value: 'date_end'
        }
      ],
      fieldsColumn: ['yard__code', 'yard__description', 'yard__date_start', 'yard__date_end']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.GETTERS.selectAll,
      selectedList: DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.MUTATIONS.setList,
      setSelectedList:
        DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.MUTATIONS.setSelectedList,
      setSelectAll: DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.ACTIONS
    })
  }
}
</script>
