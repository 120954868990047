<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :showDetailButton="showDetailButton"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
  >
    <template slot="other-buttons-actions-bottom" slot-scope="row">
      <b-btn
        v-if="row.item.file_to_download"
        variant="outline-primary"
        v-has-perms="downloadModelRolePerm"
        :title="'Download model' | translate"
        @click.prevent="downloadModel(row.item)"
      >
        <i class="fas fa-download"></i>
      </b-btn>
    </template>

  </thux-table>
</template>

<script>
import { mapActions } from 'vuex'
import { TYPES as DOCUMENT_TO_DOWNLOAD_TYPES } from './store'
import { TYPES as DOCUMENT_TYPES } from '../../document/store'

import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'

export default {
  name: 'DocumentToDownloadTable',
  mixins: [ThuxTableMixin],
  data () {
    return {
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'document_type',
          label: this.$t('Typology'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'document_type_name'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'acronym',
          label: this.$t('Acronym'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'acronym'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'document_type', 'name', 'acronym'],
      document: undefined,
      // Permissions
      downloadModelRolePerm: 'document_document_download_file'
    }
  },
  computed: {
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...DOCUMENT_TO_DOWNLOAD_TYPES.GENERIC.document.documentdownloadable.LIST.ACTIONS,
      download: DOCUMENT_TYPES.GENERIC.document.document.DETAIL.ACTIONS.download
    }),
    downloadModel (document) {
      this.download({ id: document.id, action: 'download-document', simpleFileName: document.file_to_download_filename })
    }
  }
}
</script>
