<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  >
    <template slot="status_name" slot-scope="row">
      <div class="mt-3 f-w-600 d-flex justify-content-start">
        <span :class="`fw-600 tag tag-${DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS[row.item.status]}`">
          {{row.item.status_name}}
        </span>
        <span v-if="row.item.validation_note && row.item.status === 'P'">
          <v-popover
            offset="16"
            trigger="hover"
            placement="top"
            :auto-hide="true"
          >
            <slot name="body">
              <i class="fas fa-comment"></i>
            </slot>
            <template slot="popover">
                {{row.item.validation_note}}
            </template>
          </v-popover>
          <!-- <i class="fas fa-comment" :title="row.item.validation_note"></i> -->
          </span>
        </div>
    </template>
    <template slot="other-buttons-actions-bottom" slot-scope="row">
      <b-btn v-if="row.item.attachment"
        variant="outline-primary"
        v-has-perms="downloadDocRolePerm"
        :title="'download' | translate"
        @click="downloadDocument(row.item)"
      >
        <i class="fas fa-download"></i>
      </b-btn>
    </template>
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_HISTORY_TYPES } from './store'
import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS } from '@/const'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'UploadDocumentHistoryTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS,
      fields: [
        {
          key: 'status_name',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'date_upload',
          label: this.$t('Upload date'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          value: 'date_upload'
        },
        {
          key: 'date_start',
          label: this.$t('Validity start'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          value: 'date_start'
        },
        {
          key: 'date_end',
          label: this.$t('Validity end'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          value: 'date_end'
        },
        {
          key: 'note',
          label: this.$t('Note'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: false,
          value: 'note'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: [],
      // Permissions
      downloadDocRolePerm: 'document_uploaddocument_download_attachment'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: UPLOAD_DOCUMENT_HISTORY_TYPES.GENERIC.document.uploaddocumenthistory.LIST.GETTERS.selectAll,
      selectedList: UPLOAD_DOCUMENT_HISTORY_TYPES.GENERIC.document.uploaddocumenthistory.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: UPLOAD_DOCUMENT_HISTORY_TYPES.GENERIC.document.uploaddocumenthistory.LIST.MUTATIONS.setList,
      setSelectedList:
        UPLOAD_DOCUMENT_HISTORY_TYPES.GENERIC.document.uploaddocumenthistory.LIST.MUTATIONS.setSelectedList,
      setSelectAll: UPLOAD_DOCUMENT_HISTORY_TYPES.GENERIC.document.uploaddocumenthistory.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...UPLOAD_DOCUMENT_HISTORY_TYPES.GENERIC.document.uploaddocumenthistory.LIST.ACTIONS
    }),
    downloadDocument (item) {
      const fileName = `${item.document_name}.${item.attachment_simple_filename.split('.').pop()}`
      this.download({
        id: item.id,
        action: 'download-attachment',
        simpleFileName: fileName
      })
    }
  }
}
</script>
