<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '../../../../main'
import { TYPES as DOCUMENT_TYPES } from '../../document/store'

export default {
  name: 'DocumentMixin',
  created () {
    this.setDocumentList({})
  },
  computed: {
    ...mapGetters({
      documentsList: DOCUMENT_TYPES.GENERIC.document.document.LIST.GETTERS.list
    }),
    documentOptions () {
      const options = []
      if (this.documentsList.results && this.documentsList.results.length) {
        this.documentsList.results.forEach(item => {
          options.push({
            value: item.id,
            text: item.name
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setDocumentList: DOCUMENT_TYPES.GENERIC.document.document.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getDocumentList: DOCUMENT_TYPES.GENERIC.document.document.LIST.ACTIONS.setFilters
    }),
    onSearchDocument (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getDocumentList({ filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setDocumentList({})
      }
    },
    selectDocument (item) {
      this.$set(this.form, 'document', item.value)
      this.$set(this.form, 'document_name', item.text)
    },
    clearDocument () {
      this.$set(this.form, 'document', null)
      this.$set(this.form, 'document_name', null)
    }
  }
}
</script>
