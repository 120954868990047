<template>
  <div class="wrapper panel-with-actions">
    <thux-list-component
      :fields="getFields"
      :pagination="getPaginationFields"
      @select-per-page="changePerPage"
      @change-page="changePage"
      :action-select="getActionSelectFields"
      @select-action="setAction"
      @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
      @do-action="doAction($event, fieldName)"
      :advanced-search="getAdvancedSearchFields"
      @show-advanced-search="openAdvancedSearch"
      @open-edit-form="openEditForm()"
      @open-detail-form="openDetailForm()"
    >
      <template slot="body">
        <component-table
          :list="list.results"
          :goBack="goBack"
          :params="tableParams"
        />
      </template>
    </thux-list-component>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'
import { TYPES as DOCUMENT_YARD_LINK_TYPES } from '../../document-yard-link/store'
import UploadDocumentYardTable from './UploadDocumentYardTable'

export default {
  name: 'UploadDocumentYardList',
  props: { uploadDocumentId: { required: true } },
  mixins: [ThuxListMixin],
  components: {
    'component-table': UploadDocumentYardTable
  },
  data () {
    return {
      rolePerm: ['document_uploaddocument_list'],
      actionEnablePermission: ['document_uploaddocument_enable'],
      actionDisablePermission: ['document_uploaddocument_disable'],
      searchFields: [],
      showCommonFilters: false
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.GETTERS
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...DOCUMENT_YARD_LINK_TYPES.GENERIC.document.documentyardlink.LIST.ACTIONS
    }),
    getComponentList () {
      this.setFilters({ filter__upload_document: this.uploadDocumentId })
    }
  }
}
</script>
