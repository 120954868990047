<template>
  <div>
  <hr/>
    <div class="pt-2"> {{'Other documents' | translate}}: </div>
    <div class="col-12 installment-items px-0" v-for="(document, index) in documents" :key="index">
    <document-form
      :docFilters="docFilters"
      :primaryDoc="primaryDoc"
      :index="index"
      :form-update="document"
      :reset-form="resetForm"
      :other-docs-length="documents.length"
      :show-add-button="index === documents.length - 1"
      :selected-status="selectedStatus"
      @add-document="addNewDocument"
      @remove-document="removeDocument(index)"
      @edit-other-doc="editOtherDoc($event, index)"
      @doc-form-valid-change="docValidationChange($event, index)"
    >

    </document-form>
    </div>
  <hr/>
  </div>
</template>

<script>
import DocumentForm from './DocumentForm.vue'

export default {
  name: 'OtherDocumentManager',
  components: {
    DocumentForm
  },
  watch: {
    primaryDoc: {
      handler () {
        if (!this.primaryDoc) {
          this.$set(this, 'documents', [{}])
          this.resetForm = true
        } else {
          this.resetForm = false
        }
      },
      deep: true
    },
    documents: {
      handler () {
        let documents = [...this.documents]
        documents = documents.filter((document) => {
          return Object.values(document).some((value) => { return !!value })
        })
        this.$emit(
          'update-other-docs',
          documents
        )
      },
      deep: true

    },
    documentsInvalid: {
      handler () {
        this.$emit(
          'update-document-validation',
          this.documentsInvalid
        )
      },
      deep: true

    }
  },
  props: {
    docFilters: { default: {} },
    primaryDoc: { default: undefined },
    selectedStatus: { default: undefined }
  },
  data () {
    return {
      documents: [{}],
      resetForm: false,
      documentsInvalid: [false]
    }
  },
  methods: {
    addNewDocument () {
      this.documents.push({})
      this.documentsInvalid.push(false)
    },
    removeDocument (index) {
      if (this.documents.length > 1) {
        this.documents.splice(index, 1)
        this.documentsInvalid.splice(index, 1)
      } else {
        this.$set(this, 'documents', [{}])
        this.$set(this, 'documentsInvalid', [false])
      }
    },
    editOtherDoc (document, index) {
      this.$set(this.documents, index, document)
    },
    docValidationChange (formInvalid, index) {
      this.$set(this.documentsInvalid, index, formInvalid)
    }
  },
  validations: {
    documents: {
      $each: {
        document: {},
        date_start: {},
        date_end: {}
      }
    }
  }
}
</script>
