<template>
  <div class="sidebar-filters">
    <b-button class="text-uppercase" ref="escape" variant="reset-all" @click.prevent="resetFilters()">
      <i class="fas fa-eraser" /> {{ 'Reset all filters' | translate }}
    </b-button>
    <upload-document-status-choices-list-filter
      @edit="editComponentFilters($event, 'filter__status')"
      @clean="cleanComponentFilters(['filter__status'])"
    />
    <upload-document-is-optional-list-filter
      @edit="editComponentFilters($event, 'filter__is_optional')"
      @clean="cleanComponentFilters(['filter__is_optional'])"
    />
    <organization-list-filter
      v-if="yard"
      @edit="editComponentFilters($event, 'filter__organization__in')"
      @clean="cleanComponentFilters(['filter__organization__in'])"
    />
    <yard-list-filter
      v-if="organization"
      @edit="editComponentFilters($event, 'filter__yards__id__in')"
      @clean="cleanComponentFilters(['filter__yards__id__in'])"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as UPLOAD_DOCUMENT_TYPES } from './store'
import CommonFiltersMixin from '../../common-filters/CommonFiltersMixin'

export default {
  name: 'YardDocumentCommonFilters',
  props: {
    organization: {},
    yard: {},
    cntId: {}
  },
  mixins: [CommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...UPLOAD_DOCUMENT_TYPES.GENERIC.document.uploaddocument.LIST.ACTIONS
    })
  }
}
</script>
