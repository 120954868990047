<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_CONTACT_TYPES } from '../../../organization/organization-contact/store'
import { vue } from '../../../../main'

export default {
  name: 'ContactOrganizationMixin',
  props: { contact: {} },
  created () {
    this.setContactOrganizationList({ filter__contacts: this.contact })
  },
  computed: {
    ...mapGetters({
      contactOrganizationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.GETTERS.organizationList
    }),
    contactOrganizationOptions () {
      if (this.contactOrganizationList && this.contactOrganizationList.results) {
        return this.contactOrganizationList.results.map(item => {
          return {
            value: item.id,
            text: item.name
          }
        })
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setContactOrganizationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.MUTATIONS.setOrganizationlist
    }),
    ...mapActions({
      getContactOrganizationList: ORGANIZATION_CONTACT_TYPES.GENERIC.organization.organizationcontact.LIST.ACTIONS.getOrganizationlist
    }),
    onSearchContactOrganization (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getContactOrganizationList({ filter__contacts: this.contact, filter__name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setContactOrganizationList({})
      }
    },
    selectContactOrganization (item) {
      this.$set(this.form, 'organization', item.value)
      this.$set(this.form, 'organization_name', item.text)
    },
    clearContactOrganization () {
      this.$set(this.form, 'organization', null)
      this.$set(this.form, 'organization_name', null)
    }
  }
}
</script>
